<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Импорт воспитателей
          </template>
          <template v-slot:toolbar>
            
          </template>
          <template v-slot:body>
            
            <textarea @change="test" v-model="innList" style="border:1px solid black;">
              
            </textarea>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>ИНН</th>
                <th>Фамилия</th>
                <th>Имя</th>
                <th>Отчество</th>
                <th>Номер телефона</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="teacher in teachers" :key="teacher.inn" :class="checkInn(teacher)">
                <td>{{teacher.inn}}</td>
                <td>{{teacher.firstName}}</td>
                <td>{{teacher.name}}</td>
                <td>{{teacher.patronymic}}</td>
                <td>{{teacher.contactPhone}}</td>
              </tr>
              </tbody>
            </table>
            <div style="text-align: right">
              <b-button type="submit" @click="save" variant="primary" id="save-data-button">{{
                  $t("common.save")
                }}
              </b-button>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
.table-danger td{
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
export default {
  name: "ImportKindergartener",
  components: {
    KTPortlet
  },
  data() {
    return {
      teachers:[],
      innList:'',
      form:{},
    };
  },
  mounted() {

  },
  methods: {
    checkInn(student){
      return student.inn.length !== 14 ? "table-danger" : "";
    },
    save(evt){
      let submitButton = document.getElementById('save-data-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");

      evt.preventDefault();
      let $this = this;
      this.form.teachers = this.teachers;
      ApiService.postSecured("preschoolImport/importKindergartenerJson", this.form)
          .then(function () {
            $this.$router.push({name: "PreschoolAdminKindergartners"});
          })
          .catch(({response}) => {
            console.log(response);
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    test(){
      let rawRows = this.innList.split("\n");
      let headersArray = [
          "inn",
          "firstName",
          "name",
          "patronymic",
          "contactPhone",
      ];
      this.students = [];
      rawRows.forEach((rawRow) => {
        let rowObject = {};
        let values = rawRow.split("\t");
        headersArray.forEach((header, idx) => {
          rowObject[header] = values[idx];
        });

        this.teachers.push(rowObject);
      })
    }
  }
};
</script>
